import { io } from "socket.io-client";
import { State } from "../network/state";
import { Request } from "@/network/request";
import { ref } from "vue";
import { socket } from "@/network/socket";
//intialize and connect
export default class chatStore {
  testToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0MywiaWF0IjoxNzAyMDQ2NzI0LCJleHAiOjE3MDQ2Mzg3MjR9.qqfe0JK9SCuTxeBTOSr6_O8t9MwIcc9sW0toSd9_t7c";

  getAllRoomsState = ref(new State());
  getRoomState = ref(new State());

  socketData = ref({
    socket: null,
    rooms: {},
    roomsMessages: {},
    currentRoom: [],
  });

  //connect to socket
  socketConnection() {
    this.socketData.value.socket = io(process.env.VUE_APP_SOCKET_URL, {
      transports: ["websocket"],
      autoConnect: true,
      auth: {
        authorization: `Bearer ${this.testToken}`,
      },
    });

    this.socketData.value.socket.on("connect", () => {
      console.log("connect", this.socketData.value.socket);
    });
    this.socketData.value.socket.on("connect_error", () => {
      console.log("not connect");
    });
  }

  //get rooms
  async getRooms({ onSuccess, onError } = {}) {
    let request = new Request({
      method: "get",
      endpoint: `APIS/mychat.php`,
      token: this.testToken,
    });

    await this.getAllRoomsState.value.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          value.data.forEach((item) => {
            Object.assign(this.socketData.value.rooms, { [item.id]: item });
          });
          if (typeof onSuccess === "function") {
            onSuccess(value);
          }
        },
        onError: (error) => {
          if (typeof onError === "function") {
            onError(error);
          }
        },
      }
    );
  }

  async getRoomByUserId(data, { onSuccess, onError } = {}) {
    let request = new Request({
      method: "post",
      endpoint: `messagesbyusers`,
      data: data,
      token: this.testToken,
    });

    await this.getRoomState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          Object.assign(this.socketData.roomsMessages, {
            [data.reciver_id]: value,
          });

          if (typeof onSuccess === "function") {
            onSuccess(value);
          }
        },
        onError: (error) => {
          if (typeof onError === "function") {
            onError(error);
          }
        },
      }
    );
  }
  // send messages
  async sendMessage(payload) {
    //create state for every message
    let state = new State();
    //intialize
    let messages = new socket({
      socket: this.socketData.socket,
      type: "new message",
      payload: payload,
    });
    state.trigger(
      async () => {
        return await messages.send();
      },
      {
        onSuccess: () => {},
        onError: () => {},
      }
    );
  }

  // messages events
  async getNewMessages() {
    this.socketData.socket.on("new message", async (msg) => {
      console.log(msg);
    });
  }
}

export const Chat = new chatStore();
