export class State {
  constructor(value) {
    this.value = value | {};
  }

  loading = false;
  error = null;
  valid = null;
  value = {};
  lock = false;

  async trigger(cb, { onSuccess, onError, clearonError } = {}) {
    this.error = null;
    this.loading = true;
    try {
      this.value = await cb();
      if (onSuccess) {
        onSuccess(this.value);
      }
    } catch (error) {
      this.error = error;
      if (clearonError) this.value = {};
      if (onError) onError(error);
    } finally {
      this.loading = false;
    }
  }
}
