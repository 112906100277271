<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { provide } from "vue";
import { Store } from "../src/store/index";
export default {
  name: "App",

  data: () => ({}),
  setup() {
    provide("Store", Store);
  },
};
</script>
<style>
body::-webkit-scrollbar {
  width: 0px;
}
</style>
