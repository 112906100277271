import { googleSdkLoaded } from "vue3-google-login";
import axios from "axios";
import { State } from "@/network/state";
import { Request } from "@/network/request";
import router from "@/router";

class auth {
  serialesNumbers = [];

  signUpState = new State();
  completeState = new State();

  //gmail login methods
  //init google sdk
  signInGmail() {
    googleSdkLoaded((google) => {
      google.accounts.oauth2
        .initCodeClient({
          client_id:
            "549975383888-cnd48ip1jshp5stf56n7aat5mfa991qs.apps.googleusercontent.com",
          scope: "email profile openid",
          redirect_uri: "http://localhost:8080",
          callback: (response) => {
            if (response.code) {
              this.sendCodeToBackend(response.code);
            }
          },
        })
        .requestCode();
    });
  }

  //send code to google to get user info
  async sendCodeToBackend(code) {
    var lastIndex = window.location.href.lastIndexOf("/");

    try {
      const response = await axios.post("https://oauth2.googleapis.com/token", {
        code,
        client_id:
          "549975383888-cnd48ip1jshp5stf56n7aat5mfa991qs.apps.googleusercontent.com",
        client_secret: "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",
        redirect_uri: window.location.href.substring(0, lastIndex),
        grant_type: "authorization_code",
      });

      const accessToken = response.data.access_token;

      // Fetch user details using the access token
      const userResponse = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (userResponse && userResponse.data) {
        let data = {
          uuid: userResponse.data.email,
          type_value: userResponse.data.email,
          type: "Google",
        };
        this.signUp(data);
      } else {
        console.error("Failed to fetch user details.");
      }
    } catch (error) {
      console.error("Token exchange failed:", error.response.data);
    }
  }
  async signUp(data, { onSuccess, onError } = {}) {
    let request = new Request({
      method: "post",
      endpoint: `/auth/authenticate`,
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("memo_token")}`,
        scheme: "http",
      },
    });

    await this.signUpState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          localStorage.setItem("user", JSON.stringify(value.data.user));
          localStorage.setItem("memo_token", value.data.access_token);
          localStorage.setItem("memo_refresh_token", value.data.refresh_token);
          localStorage.setItem(
            "seriales_numbers",
            JSON.stringify(value.data.numbers)
          );
          if (value.data.is_new_user) router.push("/personl-information");
          else router.push("/home");
          if (typeof onSuccess === "function") {
            onSuccess(value);
          }
        },
        onError: (error) => {
          if (typeof onError === "function") {
            onError(error);
          }
        },
      }
    );
  }
  async completeSignUp(data, { onSuccess, onError } = {}) {
    let request = new Request({
      method: "post",
      endpoint: `auth/googlesignup`,
      data: data,
    });

    await this.completeState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          localStorage.setItem("user", JSON.stringify(value.data.user));
          router.push("/home");
          if (typeof onSuccess === "function") {
            onSuccess(value);
          }
        },
        onError: (error) => {
          if (typeof onError === "function") {
            onError(error);
          }
        },
      }
    );
  }
}
export const Auth = new auth();
