import axios from "axios";
import { ValidationError } from "./error";

export class Request {
  constructor({
    baseUrl = process.env.VUE_APP_BASE_URL,
    method,
    endpoint,
    data,
    params,
    responseType,
    cancelToken,
    headers,
    token,
  }) {
    this.progress = 0;
    this.baseUrl = baseUrl;
    this.method = method;
    this.endpoint = endpoint;
    this.data = data;
    this.token = token || localStorage.getItem("memo_token");
    this.params = params;
    this.responseType = responseType;
    this.cancelToken = cancelToken;
    this.headers = headers || { Authorization: `Bearer ${this.token}` };
  }

  async send(onUploadProgress) {
    try {
      let response = await axios({
        baseURL: this.baseUrl,
        method: this.method,
        url: this.endpoint,
        data: this.data,
        headers: this.headers,
        params: this.params,
        responseType: this.responseType,
        cancelToken: this.cancelToken,
        onUploadProgress: onUploadProgress,
      });

      return response.data;
    } catch (error) {
      if (error.toJSON().message === "Network Error") {
        throw new ValidationError("Internet Error", "INTERNET ERROR");
      } else if (error.response.data.status === "fail") {
        throw new ValidationError(
          this.errorMapper(error.response.data.error),
          "USER ERROR"
        );
      }
    }
  }

  errorMapper(type) {
    const error = new Map([
      ["No internet", "Internet Error"],
      ["ERR_TIMED_OUT", "Internet Error"],
      ["ERR_INTERNET_DISCONNECTED", "Internet Error"],
    ]);
    return error.get(type) || type;
  }
  // getError(){
  //   for
  // }
}
