export class socket {
  constructor({ socket, type, payload }) {
    (this.socket = socket), (this.type = type), (this.payload = payload);
  }
  async send() {
    return new Promise((res) => {
      this.socket.emit(this.type, this.payload, (response) => {
        return res(response);
      });
    });
  }
  listen() {
    this.socket.on(this.type, (msg) => {
      return msg;
    });
  }
}
